import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  variant: PropTypes.oneOf(['h4', 'h5', 'h6', 'subtitle1']),
  color: PropTypes.oneOf(['black', 'primary', 'secondary', 'error']),
  center: PropTypes.bool,
  noPadding: PropTypes.bool,
};
const defaultProps = {
  variant: 'h6',
  color: 'black',
  center: false,
  noPadding: false,
};

const Header = ({ children, variant, color, center, noPadding }) => {
  return (
    <Typography
      variant={variant}
      color={color}
      sx={{ textAlign: center && 'center', pb: !noPadding && 3, fontWeight: 'bold' }}
    >
      {children}
    </Typography>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'warning', 'info', 'error']),
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  type: PropTypes.oneOf(['button', 'submit']),
  startIcon: PropTypes.element,
  onClick: PropTypes.func,
  rounding: PropTypes.oneOf(['sm', 'md', 'lg']),
  uppercase: PropTypes.bool,
  icon: PropTypes.element,
  hide: PropTypes.bool,
};
const defaultProps = {
  color: 'primary',
  variant: 'text',
  type: 'button',
  startIcon: null,
  onClick: null,
  submitting: false,
  disabled: false,
  rounding: 'sm',
  uppercase: false,
  icon: null,
  hide: false,
};

const Button = forwardRef(
  (
    {
      label,
      disabled,
      color,
      variant,
      type,
      onClick,
      icon,
      submitting,
      rounding,
      uppercase,
      hide,
      startIcon,
      ...rest
    },
    ref
  ) => {
    return (
      <MuiButton
        {...rest}
        type={type}
        ref={ref}
        onClick={onClick}
        disabled={disabled || submitting}
        variant={variant}
        startIcon={startIcon}
        color={color}
        sx={{
          display: hide ? 'none' : 'inline-flex',
          gap: 1,
          borderRadius: (rounding == 'md' && '5%/25%') || (rounding == 'lg' && '10%/50%'),
          textTransform: uppercase ? 'uppercase' : 'none',
          fontSize: 'inherit',
        }}
      >
        {submitting ? <CircularProgress size={15} /> : <>{icon}</>}
        {label}
      </MuiButton>
    );
  }
);

Button.displayName = 'Button';
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;

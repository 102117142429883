import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';

const ClientLayout = lazy(() => import('../modules/layouts/user/client'));
const SalesmanLayout = lazy(() => import('../modules/layouts/user/salesman'));
const ClientDetailLayout = lazy(() => import('../modules/layouts/user/salesman/clientDetail'));

const ClientLandingPage = lazy(() => import('../pages/client/landing'));
const ClientPortfolioPage = lazy(() => import('../pages/client/portfolio'));
const ClientNewProductPage = lazy(() => import('../pages/client/portfolio/newProduct'));
const ClientDocumentsPage = lazy(() => import('../pages/client/documents'));
const ClientContactsPage = lazy(() => import('../pages/client/contacts'));
const ClientTransactionsPage = lazy(() => import('../pages/client/transactions'));
const ClientSettingsPage = lazy(() => import('../pages/client/settings'));

const SalesmanClientsPage = lazy(() => import('../pages/salesman/clients'));
const SalesmanCreateClientPage = lazy(() => import('../pages/salesman/createClient'));
const SalesmanTeamPage = lazy(() => import('../pages/salesman/team'));
// const SalesmanCommissionPage = lazy(() => import('../pages/salesman/commission'));
const SalesmanSettingsPage = lazy(() => import('../pages/salesman/settings'));
const ClientDetailPortfolioPage = lazy(() => import('../pages/salesman/clientDetail/portfolio'));
const ClientDetailDocumentsPage = lazy(() => import('../pages/salesman/clientDetail/documents'));
const ClientDetailUploadDocumentsPage = lazy(() =>
  import('../pages/salesman/clientDetail/uploadDocument')
);
const ClientDetailTransactionsPage = lazy(() =>
  import('../pages/salesman/clientDetail/transactions')
);
const ClientDetailNewPortfolioProductPage = lazy(() =>
  import('../pages/salesman/clientDetail/newPortfolioProduct')
);

const UserRoutes = (
  <>
    <Route layout path="klient" element={<ClientLayout />}>
      <Route path="portfolio">
        <Route path="nove-sporeni" element={<ClientNewProductPage />} />
        <Route index element={<ClientPortfolioPage />} />
      </Route>
      <Route path="dokumenty" element={<ClientDocumentsPage />} />
      <Route path="kontakty" element={<ClientContactsPage />} />
      <Route path="transakce" element={<ClientTransactionsPage />} />
      <Route path="nastaveni" element={<ClientSettingsPage />} />
      <Route index element={<ClientLandingPage />} />
    </Route>

    <Route path="obchodnik" element={<SalesmanLayout />}>
      <Route path="klient/:id" element={<ClientDetailLayout />}>
        <Route path="portfolio">
          <Route index element={<ClientDetailPortfolioPage />} />
          <Route path="novy-produkt" element={<ClientDetailNewPortfolioProductPage />} />
        </Route>
        <Route path="dokumenty">
          <Route index element={<ClientDetailDocumentsPage />} />
          <Route path="novy" element={<ClientDetailUploadDocumentsPage />} />
        </Route>
        <Route path="transakce" element={<ClientDetailTransactionsPage />} />
        <Route index element={<Navigate to="portfolio" />} />
      </Route>
      <Route path="klienti">
        <Route index element={<SalesmanClientsPage />} />
        <Route path="novy" element={<SalesmanCreateClientPage />} />
      </Route>
      <Route path="tym" element={<SalesmanTeamPage />} />
      {/* <Route path="provize" element={<SalesmanCommissionPage />} /> */}
      <Route path="nastaveni" element={<SalesmanSettingsPage />} />
      <Route index element={<Navigate to="klienti" />} />
    </Route>
  </>
);

export default UserRoutes;

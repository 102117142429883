import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import createTheme from '../../theme';
import { useOnLogout } from '../auth/hooks';

export const context = createContext();

const BRANDING_KEY = 'sg_branding_data';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

const BrandingProvider = ({ children }) => {
  const localBrand = useMemo(() => {
    const raw = localStorage.getItem(BRANDING_KEY);
    const data = raw ? JSON.parse(raw) : { theme: {}, logos: null };
    return { theme: createTheme(data.theme || {}), logos: data.logos };
  }, []);

  const [branding, setBranding] = useState(localBrand);

  const onLogout = useCallback(
    () => setBranding({ theme: createTheme({}), logos: null }),
    [setBranding]
  );
  useOnLogout(onLogout);
  const doSetBranding = useCallback(
    (b) => {
      localStorage.setItem(BRANDING_KEY, JSON.stringify(b));
      setBranding(b);
    },
    [setBranding]
  );

  return (
    <context.Provider value={{ branding, setBranding: doSetBranding }}>{children}</context.Provider>
  );
};

BrandingProvider.propTypes = propTypes;

export default BrandingProvider;

import React, { useState } from 'react';
import { flatten, map, pipe, range } from 'ramda';

import PageContainer from '../../../components/pageContainer';
import Heading from '../../../components/text/heading';
import Grid from '../../../components/grid';
import { useAuthToken } from '../../../modules/auth/hooks';
import Select from '../../../components/form/select';
import Button from '../../../components/button';

const padMonth = (m) => (m < 10 ? `0${m}` : m);

const yearOptions = pipe(
  range(2022),
  map((year) =>
    pipe(
      () => range(1, 13),
      map((m) => `${year}-${padMonth(m)}`)
    )(1)
  ),
  flatten,
  map((year) => ({ value: year, label: year }))
)(new Date().getFullYear() + 1);

const IncomingPaymentsReportPage = () => {
  const token = useAuthToken();
  const [period, setPeriod] = useState(null);

  return (
    <PageContainer maxWidth="xl">
      <Heading noPadding>Sestava - příchozí platby</Heading>
      <Grid container spacing={4}>
        <Grid item flex={1}>
          <Select
            options={yearOptions}
            label="Období"
            onChange={(e) => setPeriod(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            label="Stáhnout"
            onClick={(e) => {
              e.preventDefault();

              window.location.href = `/admin-reports/payments-report/${period}-01?token=${token}`;
            }}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default IncomingPaymentsReportPage;

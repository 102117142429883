import { gql } from '@apollo/client/core';
import { GraphQLScalarType, Kind } from 'graphql';
import { makeExecutableSchema } from '@graphql-tools/schema';

import schemaString from '../../../../server/priv/schema.graphql.json';

const typeDefs = gql(schemaString);

const resolvers = {
  DateTime: new GraphQLScalarType({
    name: 'DateTime',
    serialize: (parsed) => parsed && parsed.toISOString(),
    parseValue: (raw) => raw && new Date(raw),
    parseLiteral(ast) {
      if (ast.kind === Kind.STRING || ast.kind === Kind.INT) {
        return new Date(ast.value);
      }
      return null;
    },
  }),
  Date: new GraphQLScalarType({
    name: 'Date',
    serialize: (parsed) => parsed && parsed.toISOString(),
    parseValue: (raw) => raw && new Date(raw),
    parseLiteral(ast) {
      if (ast.kind === Kind.STRING || ast.kind === Kind.INT) {
        return new Date(ast.value);
      }
      return null;
    },
  }),
  Decimal: new GraphQLScalarType({
    name: 'Decimal',
    serialize: (parsed) => parsed && parsed.toFixed(6),
    parseValue: (raw) => raw && Number(raw),
    parseLiteral(ast) {
      if (ast.kind === Kind.STRING || ast.kind === Kind.INT || ast.kind === Kind.FLOAT) {
        return Number(ast.value);
      }
      return null;
    },
  }),
  Json: new GraphQLScalarType({
    name: 'Json',
    serialize: (parsed) => JSON.stringify(parsed),
    parseValue: (raw) => raw && JSON.parse(raw),
    parseLiteral(ast) {
      if (ast.kind === Kind.STRING) {
        return JSON.parse(ast.value);
      }
      return null;
    },
  }),
};

export default makeExecutableSchema({ typeDefs, resolvers });

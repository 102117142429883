import { useState, useContext, useEffect } from 'react';
import {
  BehaviorSubject,
  catchError,
  map,
  mergeMap,
  of,
  tap,
  firstValueFrom,
  pairwise,
  filter,
  ignoreElements,
} from 'rxjs';

import { context } from './context';

export const useAuthStatus = () => {
  const { authStatus$ } = useContext(context);
  const [state, setState] = useState(authStatus$.getValue());

  useEffect(() => {
    const subscription = authStatus$.subscribe(setState);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return state;
};

export const useAuth = () => {
  const authState = useContext(context);
  const [loadingState, setLoadingState] = useState({ loading: false, error: null });
  const [state$] = useState(() => new BehaviorSubject(loadingState));
  const [actions] = useState({
    login: (credentials) =>
      firstValueFrom(
        of(credentials).pipe(
          tap(() => state$.next({ loading: true, error: null })),
          mergeMap(authState.login$),
          map(() => ({ loading: false, error: null })),
          catchError((error) => of({ error, loading: false })),
          tap((data) => state$.next(data))
        )
      ),
    logout: () =>
      firstValueFrom(
        of(true).pipe(
          tap(() => state$.next({ loading: true, error: null })),
          mergeMap(authState.logout$),
          map(() => ({ loading: false, error: null })),
          catchError((error) => of({ error, loading: false })),
          tap((data) => state$.next(data))
        )
      ),
  });

  useEffect(() => {
    const subscription = state$.subscribe(setLoadingState);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    loading: loadingState.loading,
    error: loadingState.error,
    login: actions.login,
    logout: actions.logout,
  };
};

export const useOnLogout = (cb) => {
  const { authStatus$ } = useContext(context);

  useEffect(() => {
    const subscription = authStatus$
      .pipe(
        pairwise(),
        filter(([oldVal, newVal]) => oldVal.isAuthenticated && !newVal.isAuthenticated),
        tap(() => cb()),
        ignoreElements()
      )
      .subscribe();

    return () => subscription.unsubscribe();
  }, []);
};

export const useAuthToken = () => {
  const authState = useContext(context);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const subscription = authState.getAuthToken$().subscribe((newToken) => {
      setToken(newToken);
    });

    return () => subscription.unsubscribe();
  }, []);

  return token;
};

import { useCallback, useContext, useMemo } from 'react';
import { applySpec, pathOr, pipe } from 'ramda';

import createTheme from '../../theme';
import { context } from './context';
import { horizontalLogo, verticalLogo, logo } from './logos';

export const useBranding = () => {
  return useContext(context);
};

export const useBrandedTheme = () => {
  const {
    branding: { theme },
  } = useBranding();

  return theme;
};

export const useSetBranding = () => {
  const { setBranding } = useBranding();

  return useCallback((branding) => {
    setBranding(
      applySpec({
        theme: pipe(pathOr({}, ['colors']), createTheme),
        logos: pathOr({}, ['logos']),
      })(branding)
    );
  });
};

export const useBrandedLogos = () => {
  const {
    branding: { logos },
  } = useBranding();

  return useMemo(
    () =>
      applySpec({
        horizontalLogo: pathOr(horizontalLogo, ['horizontalLogo']),
        verticalLogo: pathOr(verticalLogo, ['verticalLogo']),
        logo: pathOr(logo, ['logo']),
      })(logos),
    [logos]
  );
};

import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import Router from './router';
import AuthenticationProvider from './modules/auth/context';
import SgApolloProvider from './modules/api';
import BrandingProvider, { useBrandedTheme } from './modules/branding';
import PreviewProvider from './modules/preview/context';
import CookieConsentProvider from './modules/cookieBanner/CookieConsentProvider';

const ThemedApp = () => {
  const theme = useBrandedTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookieConsentProvider />
      <Router />
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <SnackbarProvider>
      <AuthenticationProvider>
        <SgApolloProvider>
          <BrandingProvider>
            <PreviewProvider>
              <ThemedApp />
            </PreviewProvider>
          </BrandingProvider>
        </SgApolloProvider>
      </AuthenticationProvider>
    </SnackbarProvider>
  );
};

export default App;

import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export const context = createContext();

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

const PreviewProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const previewUserId = window.localStorage.getItem('previewUserId');
    if (previewUserId) setUserId(previewUserId);
  }, []);

  const doSetUserId = (userId) => {
    setUserId(userId);
    window.localStorage.setItem('previewUserId', userId);
  };

  const leavePreview = () => {
    setUserId(null);
    window.localStorage.removeItem('previewUserId');
  };

  return (
    <context.Provider value={{ userId, setUserId: doSetUserId, leavePreview }}>
      {children}
    </context.Provider>
  );
};

PreviewProvider.propTypes = propTypes;

export default PreviewProvider;

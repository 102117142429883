import React from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};
const defaultProps = {
  maxWidth: 'lg',
};

const PageContainer = ({ maxWidth, children }) => {
  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 2,
      }}
    >
      {children}
    </Container>
  );
};

PageContainer.propTypes = propTypes;
PageContainer.defaultProps = defaultProps;

export default PageContainer;

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client';
import { withScalars } from 'apollo-link-scalars';
import { createLink } from 'apollo-absinthe-upload-link';

import { context } from '../auth/context';
import { useOnLogout } from '../auth/hooks';
import createCustomFetch from './customFetch';

import schema from './schema';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

const SgApolloProvider = ({ children }) => {
  const authContext = useContext(context);
  const [client] = useState(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          withScalars({ schema }),
          createLink({
            uri: '/api',
            fetch: createCustomFetch(authContext),
          }),
        ]),
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                Sales: {
                  company: {
                    merge(existing, incoming, { mergeObjects }) {
                      return mergeObjects(existing, incoming);
                    },
                  },
                },
                Client: {
                  company: {
                    merge(existing, incoming, { mergeObjects }) {
                      return mergeObjects(existing, incoming);
                    },
                  },
                },
                Reseller: {
                  company: {
                    merge(existing, incoming, { mergeObjects }) {
                      return mergeObjects(existing, incoming);
                    },
                  },
                },
              },
            },
          },
        }),
      })
  );

  useOnLogout(() => {
    client.clearStore();
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

SgApolloProvider.propTypes = propTypes;

export default SgApolloProvider;

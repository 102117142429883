import createTheme from '@mui/material/styles/createTheme';

const commonScrollBarStyles = {
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    borderRadius: 10,
    height: 10,
    width: 10,
    margin: '10px 5px',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: 10,
    backgroundColor: '#d3d3d3',
  },
  '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
    borderRadius: 10,
    backgroundColor: '#f0f0f0',
  },
};

const commonInputStyles = {
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '&[type=number]': {
    MozAppearance: 'textfield',
  },
};

const getPrimaryColor = ({ primary }) => {
  if (primary) return primary;
  return '#051D87';
};

const getSecondaryColor = ({ secondary }) => {
  if (secondary) return secondary;
  return '#FFD24A';
};

const getTopBarColor = ({ topbar }) => {
  if (topbar) return topbar;
  return '#ffffff';
};

export default (colors) =>
  createTheme({
    palette: {
      primary: {
        main: getPrimaryColor(colors),
      },
      secondary: {
        main: getSecondaryColor(colors),
      },
      topbar: {
        main: getTopBarColor(colors),
      },
      gold: {
        main: '#FFD24A',
      },
      silver: {
        main: '#BCBCBC',
      },
      white: {
        main: '#FFFFFF',
      },
    },
    components: {
      MuiTableContainer: {
        styleOverrides: {
          root: { ...commonScrollBarStyles },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: { ...commonScrollBarStyles },
        },
      },
      MuiList: {
        styleOverrides: {
          root: { ...commonScrollBarStyles },
        },
      },
      MuiCollapse: {
        styleOverrides: {
          root: {
            borderLeft: '3px dashed #808080',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: { ...commonInputStyles },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: { ...commonInputStyles },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: '#F5F6FA',
              boxShadow: 'none',
            },
            '& .MuiDataGrid-pinnedColumns': {
              backgroundColor: '#F5F6FA',
            },
          },
          virtualScroller: {
            '& .MuiDataGrid-pinnedColumns': { backgroundColor: '#F5F6FA' },
            ...commonScrollBarStyles,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: { ...commonScrollBarStyles },
        },
      },
    },
  });

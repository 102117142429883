import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import HelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SelectInput from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FieldTooltip from '../fieldTooltip';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  inputProps: PropTypes.shape(),
  value: PropTypes.string,
  isControlled: PropTypes.bool,
  onChange: PropTypes.func,
  renderLabel: PropTypes.func,
  moreInfo: PropTypes.string,
};

const defaultProps = {
  value: null,
  isControlled: false,
  moreInfo: null,
};

const Select = ({
  options,
  label,
  value,
  onChange,
  isControlled,
  inputProps,
  error,
  renderLabel,
  moreInfo,
  ...rest
}) => {
  const [actualValue, setActualValue] = useState([]);
  const id = useMemo(() => `select-label-id-${Math.round(Math.random() * 100000)}`, []);
  const actualOnChange = useMemo(
    () =>
      isControlled
        ? undefined
        : (e) => {
            setActualValue(e.target.value);
            if (onChange) {
              onChange(e);
            }
          },
    []
  );

  return (
    <FormControl fullWidth error={!!error} sx={{ position: 'relative', width: '100%' }}>
      <InputLabel id={id} variant="standard">
        {label}
      </InputLabel>
      <SelectInput
        {...rest}
        value={isControlled ? value : actualValue}
        labelId={id}
        label={label}
        variant="standard"
        onChange={actualOnChange}
        inputProps={inputProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {renderLabel ? renderLabel(option.label) : option.label}
          </MenuItem>
        ))}
      </SelectInput>
      <HelperText sx={{ mx: 0 }} error={!!error}>
        {error}
      </HelperText>
      {!!moreInfo && <FieldTooltip info={moreInfo} />}
    </FormControl>
  );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';

import 'regenerator-runtime';

import App from './App';

LicenseInfo.setLicenseKey(
  'e62c7bb299d4bc162a19d4f2b298e06dTz03NDI1MixFPTE3MjU3MzA2MjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const root = createRoot(document.getElementById('root'));
root.render(<App />);

import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import IncomingPaymentsReportPage from '../pages/admin/reports/incomingPayments';

const AdminLayout = lazy(() => import('../modules/layouts/admin'));

const AdminOverviewPage = lazy(() => import('../pages/admin/overview'));
const AdminBankPage = lazy(() => import('../pages/admin/bankAccounts'));
const AdminNewBankAccountPage = lazy(() => import('../pages/admin/bankAccounts/new'));
const AdminBankAccountDetail = lazy(() => import('../pages/admin/bankAccounts/detail'));
const AdminEshopProductsPage = lazy(() => import('../pages/admin/eshopProducts'));
const AdminInvestmentsPage = lazy(() => import('../pages/admin/investments'));
const AdminNewInvestmentProductPage = lazy(() => import('../pages/admin/investments/product/new'));
const AdminEditInvestmentProductPage = lazy(() =>
  import('../pages/admin/investments/product/edit')
);
const AdminNewInvestmentTargetPage = lazy(() => import('../pages/admin/investments/target/new'));
const AdminEditInvestmentTargetPage = lazy(() => import('../pages/admin/investments/target/edit'));
const AdminInvestmentTargetDetailPage = lazy(() =>
  import('../pages/admin/investments/target/detail')
);
const AdminClientsPage = lazy(() => import('../pages/admin/users/clients'));
const AdminCreateClientPage = lazy(() => import('../pages/admin/users/createClient'));
const AdminUpdateClientPage = lazy(() => import('../pages/admin/users/updateClient'));
const AdminSalesmenPage = lazy(() => import('../pages/admin/users/salesmen'));
const AdminUserDetailPage = lazy(() => import('../pages/admin/users/detail'));
const AdminNewDocumentPage = lazy(() => import('../pages/admin/users/uploadDocument'));
const AdminNewProductPage = lazy(() => import('../pages/admin/users/newPortfolioProduct'));
const AdminPromoteClientPage = lazy(() => import('../pages/admin/users/promoteClient'));
const AdminListPage = lazy(() => import('../pages/admin/administrators'));
const AdminCreateAdminPage = lazy(() => import('../pages/admin/administrators/create'));
const AdminTransactionsPage = lazy(() => import('../pages/admin/transactions'));
const AdminPairTransaction = lazy(() => import('../pages/admin/transactions/manualPairing'));
const AdminCompletedRetainedProductsPage = lazy(() =>
  import('../pages/admin/completedRetainedProducts')
);
const AdminPurchaseLedgerPage = lazy(() => import('../pages/admin/purchaseLedger'));
const AdminMonthlyStatementPage = lazy(() => import('../pages/admin/monthlyStatements'));
const AdminMonthlyStatementDetailPage = lazy(() =>
  import('../pages/admin/monthlyStatements/detail')
);
const AdminClosedContractsReportPage = lazy(() => import('../pages/admin/reports/closedContracts'));
const AdminSalesmenReportPage = lazy(() => import('../pages/admin/reports/salesmen'));
const ApprovePortfolioPage = lazy(() => import('../pages/admin/users/approvePortfolio'));
const ResellersPage = lazy(() => import('../pages/admin/resellers'));
const NewResellerPage = lazy(() => import('../pages/admin/resellers/new'));
const ResellerDetailPage = lazy(() => import('../pages/admin/resellers/detail'));
const EditResellerPage = lazy(() => import('../pages/admin/resellers/edit'));
const CreateResellerAdminPage = lazy(() => import('../pages/admin/resellers/createAdmin'));
const DocumentsPage = lazy(() => import('../pages/admin/documents'));
const NewDocumentsPage = lazy(() => import('../pages/admin/documents/new'));
const PromoCodesPage = lazy(() => import('../pages/admin/promoCodes'));
const SettingsPage = lazy(() => import('../pages/admin/settings'));
const MoveUserPage = lazy(() => import('../pages/admin/users/moveUser'));
const ChangeResellerThemePage = lazy(() => import('../pages/admin/resellers/changeTheme'));
const AdminContractPage = lazy(() => import('../pages/admin/contract'));

const AdminRoutes = (
  <>
    <Route path="admin/smlouva/:id" element={<AdminContractPage />} />
    <Route layout path="admin" element={<AdminLayout />}>
      <Route path="prehledy" element={<AdminOverviewPage />} />
      <Route path="promo-kody" element={<PromoCodesPage />} />
      <Route path="nastaveni" element={<SettingsPage />} />
      <Route path="schvalit-portfolio/:id" element={<ApprovePortfolioPage />} />
      <Route path="eshop-produkty" element={<AdminEshopProductsPage />} />
      <Route path="investice">
        <Route index element={<AdminInvestmentsPage />} />
        <Route path="novy-produkt" element={<AdminNewInvestmentProductPage />} />
        <Route path="upravit-produkt/:id" element={<AdminEditInvestmentProductPage />} />
        <Route path="novy-cil" element={<AdminNewInvestmentTargetPage />} />
        <Route path="cil/:id">
          <Route index element={<Navigate to="detail" />} />
          <Route path="upravit" element={<AdminEditInvestmentTargetPage />} />
          <Route path="detail" element={<AdminInvestmentTargetDetailPage />} />
        </Route>
      </Route>
      <Route path="klienti">
        <Route index element={<AdminClientsPage />} />
        <Route path="novy" element={<AdminCreateClientPage />} />
      </Route>
      <Route path="detail-uzivatele/:id">
        <Route index element={<AdminUserDetailPage />} />
        <Route path="povysit" element={<AdminPromoteClientPage />} />
        <Route path="novy-produkt" element={<AdminNewProductPage />} />
        <Route path="novy-dokument" element={<AdminNewDocumentPage />} />
        <Route path="upravit" element={<AdminUpdateClientPage />} />
        <Route path="presunout" element={<MoveUserPage />} />
      </Route>
      <Route path="obchodnici" element={<AdminSalesmenPage />} />
      <Route path="transakce">
        <Route index element={<AdminTransactionsPage />} />
        <Route path="sparovat/:id" element={<AdminPairTransaction />} />
      </Route>
      <Route path="nasporene-slitky" element={<AdminCompletedRetainedProductsPage />} />
      <Route path="kniha-nakupu" element={<AdminPurchaseLedgerPage />} />
      <Route path="bankovni-ucty">
        <Route index element={<AdminBankPage />} />
        <Route path="novy" element={<AdminNewBankAccountPage />} />
        <Route path="detail/:id" element={<AdminBankAccountDetail />} />
      </Route>
      <Route path="administratori">
        <Route index element={<AdminListPage />} />
        <Route path="novy" element={<AdminCreateAdminPage />} />
      </Route>
      <Route path="mesicni-uzaverky">
        <Route index element={<AdminMonthlyStatementPage />} />
        <Route path="detail/:period" element={<AdminMonthlyStatementDetailPage />} />
      </Route>
      <Route path="sestavy">
        <Route path="uzavrene-smlouvy" element={<AdminClosedContractsReportPage />} />
        <Route path="obchodnici" element={<AdminSalesmenReportPage />} />
        <Route path="prichozi-platby" element={<IncomingPaymentsReportPage />} />
      </Route>
      <Route path="preprodejci">
        <Route index element={<ResellersPage />} />
        <Route path="novy" element={<NewResellerPage />} />
        <Route path="detail/:id">
          <Route index element={<ResellerDetailPage />} />
          <Route path="upravit" element={<EditResellerPage />} />
          <Route path="pridat-spravce" element={<CreateResellerAdminPage />} />
          <Route path="nastavit-vzhled" element={<ChangeResellerThemePage />} />
        </Route>
      </Route>
      <Route path="dokumenty">
        <Route path="novy" element={<NewDocumentsPage />} />
        <Route index element={<DocumentsPage />} />
      </Route>
      <Route index element={<Navigate to="prehledy" />} />
    </Route>
  </>
);

export default AdminRoutes;

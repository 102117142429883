import React, { useEffect } from 'react';
// import TagManager from 'react-gtm-module';

import useConsentCookie from './useConsentCookie';
import CookieBanner from './CookieBanner';

const CookieConsentProvider = () => {
  const { isGranted, consent, setConsent } = useConsentCookie();

  useEffect(() => {
    if (isGranted && consent.analytics) {
      // TODO: enable once GTM ID is given
      // const tagManagerArgs = {
      //   gtmId: 'GTM-000000',
      // };
      // TagManager.initialize(tagManagerArgs);
    }
  }, [isGranted]);

  return <CookieBanner open={!isGranted} setConsent={setConsent} />;
};

export default CookieConsentProvider;

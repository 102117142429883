import { Reader } from 'monet';
import { weave, weaveLazy } from 'ramda-adjunct';
import { ajax as rxAjax } from 'rxjs/ajax';
import {
  BehaviorSubject,
  tap,
  of,
  mergeMap,
  map,
  delay,
  catchError,
  Observable,
  exhaustMap,
  share,
  Subject,
  take,
} from 'rxjs';
import { identity } from 'ramda';

export const _notAuthenticatedState = () => ({
  isAuthenticated: false,
  role: null,
  accessToken: null,
  refreshToken: null,
});

export const _authenticatedState = ({ accessToken, refreshToken, role }) => ({
  role,
  accessToken,
  refreshToken,
  isAuthenticated: true,
});

export const _initAuthState = () =>
  Reader(({ API, authState$ }) => {
    const authData = API.readAuthData();

    if (authData) {
      authState$.next(_authenticatedState(authData));
    } else {
      authState$.next(_notAuthenticatedState());
    }
  });

export const _persistAuthData = (authData) =>
  Reader(({ config }) => config.storage.setItem(config.storageKey, JSON.stringify(authData)));

export const _readAuthData = () =>
  Reader(({ config }) => JSON.parse(config.storage.getItem(config.storageKey)));

export const _clearAuthData = () =>
  Reader(({ config }) => config.storage.removeItem(config.storageKey));

export const _callTokenApi$ = (payload) =>
  Reader(({ config: { uri, ajax } }) =>
    ajax({ url: uri, method: 'POST', body: payload }).pipe(map(({ response }) => response))
  );

export const _needsRefresh = (token) =>
  Reader(
    ({ config }) => Date.now() + config.tokenRenewTolerance > new Date(token.expiresAt).getTime()
  );

export const _doRefreshToken$ = () =>
  Reader(({ authState$, API }) => {
    const authData = authState$.getValue();

    if (authData.isAuthenticated && API.needsRefresh(authData.accessToken)) {
      return API.callTokenApi$({ refreshToken: authData.refreshToken.token }).pipe(
        map(_authenticatedState),
        tap((authState) => authState$.next(_authenticatedState(authState))),
        tap((authState) => API.persistAuthData(_authenticatedState(authState))),
        catchError((err) => {
          if (err.status === 401) {
            const newState = _notAuthenticatedState();
            authState$.next(newState);
            API.clearAuthData();

            return of(newState);
          }
          return of(authState$.getValue());
        })
      );
    }

    return of(authData);
  });

const login$ = (credentials) =>
  Reader(({ authState$, API }) =>
    of(credentials).pipe(
      mergeMap(API.callTokenApi$),
      tap((authData) => API.persistAuthData(authData)),
      tap((authData) => authState$.next(_authenticatedState(authData))),
      map(identity)
    )
  );

const getAuthHeaders$ = () =>
  Reader(({ config, API }) =>
    API.doRefreshToken$().pipe(
      map((authData) => {
        if (!authData.isAuthenticated) {
          return {};
        }

        return { [config.authTokenHeader]: authData.accessToken.token };
      })
    )
  );

const getAuthToken$ = () =>
  Reader(({ authState$ }) =>
    authState$.pipe(
      map((authState) => {
        if (!authState.isAuthenticated) {
          return null;
        }

        return authState.accessToken.token;
      })
    )
  );

const logout$ = () =>
  Reader(({ authState$, API }) =>
    of(true).pipe(
      tap(API.clearAuthData),
      delay(150),
      tap(() => authState$.next(_notAuthenticatedState()))
    )
  );

const _authHeadersGetter$ = Reader(({ API, authHeadersSubject$ }) =>
  authHeadersSubject$.pipe(
    exhaustMap(() => API.getAuthHeaders$()),
    share()
  )
);

const _getAuthHeadersDeduplicated$ = () =>
  Reader(
    ({ authHeadersSubject$, authHeadersGetter$ }) =>
      new Observable((observer) => {
        const subscription = authHeadersGetter$.pipe(take(1)).subscribe(observer);
        authHeadersSubject$.next(1);

        return () => subscription.unsubscribe();
      })
  );

export default ({
  uri = '/api/v2_tokens',
  storageKey = 'sgAuthData',
  authTokenHeader = 'x-auth-token',
  // 5 mins default
  tokenRenewTolerance = 5 * 60 * 1000,
  storage = localStorage,
  ajax = rxAjax,
} = {}) => {
  const config = { uri, storageKey, authTokenHeader, tokenRenewTolerance, storage, ajax };
  const authState$ = new BehaviorSubject({});
  const authStatus$ = new BehaviorSubject({});
  const authHeadersSubject$ = new Subject();

  const API = {
    initAuthState: weaveLazy(_initAuthState, () => ({ API, config, authState$ })),
    needsRefresh: weaveLazy(_needsRefresh, () => ({ API, config, authState$ })),
    doRefreshToken$: weaveLazy(_doRefreshToken$, () => ({ API, config, authState$ })),
    readAuthData: weaveLazy(_readAuthData, () => ({ API, config, authState$ })),
    persistAuthData: weaveLazy(_persistAuthData, () => ({ API, config, authState$ })),
    clearAuthData: weaveLazy(_clearAuthData, () => ({ API, config, authState$ })),
    callTokenApi$: weaveLazy(_callTokenApi$, () => ({ API, config, authState$ })),
    getAuthHeaders$: weaveLazy(getAuthHeaders$, () => ({ config, authState$, API })),
  };

  const authHeadersGetter$ = _authHeadersGetter$.run({ API, authHeadersSubject$ });

  authState$
    .pipe(map(({ isAuthenticated, role }) => ({ isAuthenticated, role })))
    .subscribe(authStatus$);
  API.initAuthState();

  return {
    authStatus$,
    login$: weave(login$, { config, authState$, API }),
    logout$: weave(logout$, { config, authState$, API }),
    getAuthHeaders$: weave(_getAuthHeadersDeduplicated$, {
      authHeadersSubject$,
      authHeadersGetter$,
    }),
    getAuthToken$: weave(getAuthToken$, { authState$ }),
  };
};

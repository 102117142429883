import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const ResellerLayout = lazy(() => import('../modules/layouts/reseller'));
const ChangeThemePage = lazy(() => import('../pages/reseller/changeTheme'));
const OverviewPage = lazy(() => import('../pages/reseller/overview'));
const ClientsPage = lazy(() => import('../pages/reseller/users/clients'));
const SalesmenPage = lazy(() => import('../pages/reseller/users/salesmen'));
const UsersPage = lazy(() => import('../pages/reseller/users'));
const UserDetailPage = lazy(() => import('../pages/reseller/users/detail'));
const NewUserPage = lazy(() => import('../pages/reseller/users/new'));
const NewUserDocumentsPage = lazy(() => import('../pages/reseller/users/newDocuments'));
const NewPortfolioPage = lazy(() => import('../pages/reseller/users/newPortfolio'));

const ResellerRoutes = (
  <Route layout path="preprodejce" element={<ResellerLayout />}>
    <Route path="prehledy" element={<OverviewPage />} />
    <Route path="klienti" element={<ClientsPage />} />
    <Route path="obchodnici" element={<SalesmenPage />} />
    <Route path="uzivatele">
      <Route path="novy" element={<NewUserPage />} />
      <Route path="detail/:id">
        <Route path="novy-dokument" element={<NewUserDocumentsPage />} />
        <Route path="novy-produkt" element={<NewPortfolioPage />} />
        <Route index element={<UserDetailPage />} />
      </Route>
      <Route index element={<UsersPage />} />
    </Route>
    <Route path="zmenit-tematiku" element={<ChangeThemePage />} />
    <Route index element={<Navigate to="prehledy" />} />
  </Route>
);

export default ResellerRoutes;

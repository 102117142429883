import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const propTypes = {
  info: PropTypes.string.isRequired,
};
const defaultProps = {};

const FieldTooltip = ({ info }) => {
  return (
    <Box sx={{ fontSize: 24, position: 'absolute', top: -4, right: -12 }}>
      <Tooltip title={info}>
        <HelpOutlineIcon htmlColor="#CCCCCC" />
      </Tooltip>
    </Box>
  );
};

FieldTooltip.propTypes = propTypes;
FieldTooltip.defaultProps = defaultProps;

export default FieldTooltip;

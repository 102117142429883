import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';

const propTypes = {
  open: PropTypes.bool,
  setConsent: PropTypes.func.isRequired,
};

const defaultProps = {
  open: false,
};

const options = {
  analytics: {
    id: 'analytics',
    title: 'Analytické cookies',
    description:
      'Tyto cookies nám pomáhají pochopit, jak naše webové stránky používáte, a tím stránky zlepšit a přizpůsobit je vašim potřebám.',
  },
  marketing: {
    id: 'marketing',
    title: 'Marketingové cookies',
    description:
      'Tyto cookies nám umožňují sledovat chování návštěvníků našich webových stránek a lépe přizpůsobit naši reklamu vašim zájmům.',
  },
};

const CookieBanner = ({ open, setConsent }) => {
  const [detailed, setDetailed] = useState(false);
  const [state, setState] = useState(
    Object.keys(options).reduce((acc, k) => ({ ...acc, [k]: false }), {})
  );

  return (
    <Dialog open={open} onClose={() => {}}>
      {!detailed && <DialogTitle>Souhlas s používáním cookies</DialogTitle>}
      {detailed && <DialogTitle>Podrobné nastavení cookies</DialogTitle>}
      <DialogContent>
        {!detailed && (
          <DialogContentText>
            V Synergy Gold a.s. používáme cookies pro zajištění správného chodu webu, k analýze
            návštěvnosti, ke sledování výkonu, personalizaci obsahu a k cílení reklam. Informace o
            tom, jak naše stránky používáte, sdílíme se svými partnery pro sociální média, inzerci a
            webovou analytiku. Podrobnější nastavení si můžete jednoduše zobrazit kliknutím na
            &quot;podrobné nastavení cookies&quot;. Nastavení cookies můžete také upravit v
            nastavení vašeho internetového prohlížeče.
          </DialogContentText>
        )}
        {detailed && (
          <>
            <DialogContentText>
              Cookies jsou malé textové soubory, které jsou při vaší návštěvě webových stránek
              společnosti Synergy Gold a.s. těmito stránkami nebo stránkami našich partnerů zasílány
              a ukládány do vašeho prohlížeče.
            </DialogContentText>
            <Box sx={{ height: 10 }} />
            <DialogContentText variant="h5">Nastavení</DialogContentText>
            <FormGroup>
              <FormControlLabel
                disabled
                control={<Switch defaultChecked />}
                label={
                  <>
                    Nezbytné (provozní) cookies{' '}
                    <Tooltip title="Tyto cookies jsou nutné pro zajištění základní funkčnosti webových stránek a k jejich zabezpečení.">
                      <HelpIcon fontSize="small" />
                    </Tooltip>
                  </>
                }
              />
              {Object.values(options).map((opt) => (
                <FormControlLabel
                  key={opt.id}
                  control={
                    <Switch
                      checked={state[opt.id]}
                      onChange={(e) => setState({ ...state, [opt.id]: e.target.checked })}
                    />
                  }
                  label={
                    <>
                      {opt.title}{' '}
                      <Tooltip title={opt.description}>
                        <HelpIcon fontSize="small" />
                      </Tooltip>
                    </>
                  }
                />
              ))}
            </FormGroup>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!detailed && (
          <Button
            size="small"
            onClick={() => {
              setDetailed(true);
            }}
          >
            Podrobné nastavení cookies
          </Button>
        )}
        {detailed && (
          <Button
            size="small"
            onClick={() => {
              setConsent(state);
            }}
          >
            Uložit nastavení cookies
          </Button>
        )}
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            setConsent(Object.keys(options).reduce((acc, k) => ({ ...acc, [k]: true }), {}));
          }}
        >
          Souhlasím se vším
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CookieBanner.propTypes = propTypes;
CookieBanner.defaultProps = defaultProps;

export default CookieBanner;

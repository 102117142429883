import { useCallback, useMemo, useState } from 'react';
import Cookies from 'js-cookie';

const CONSENT_KEY = 'cookieConsent';

const getConsentCookie = () => {
  const cc = Cookies.get(CONSENT_KEY);
  if (cc) {
    return JSON.parse(cc);
  }
  return null;
};

// const c = {
//   grantedTS: 'ISO Date',
//   consent: {
//     analytics: true,
//     marketing: true,
//     ...,
//   }
// };

export default () => {
  const consentCookie = useMemo(getConsentCookie, []);
  const [state, setState] = useState(consentCookie);
  const setConsent = useCallback((consent) => {
    const ncs = { consent, grantedTS: new Date().toISOString() };
    Cookies.set(CONSENT_KEY, JSON.stringify(ncs));
    setState(ncs);
  }, []);

  return { ...state, isGranted: !!state, setConsent };
};

import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const AuthenticationLayout = lazy(() => import('../modules/layouts/auth'));

const LoginPage = lazy(() => import('../pages/auth/login'));
const RegistrationPage = lazy(() => import('../pages/auth/registration'));
const ForgottenPasswordPage = lazy(() => import('../pages/auth/forgottenPassword'));
const ResetPasswordPage = lazy(() => import('../pages/auth/resetPassword'));
const EmailSentPage = lazy(() => import('../pages/auth/emailSent'));
const ClaimInvitationPage = lazy(() => import('../pages/auth/claimInvitation'));

const AuthRoutes = (
  <Route layout path="/" element={<AuthenticationLayout />}>
    <Route path="registrace" element={<RegistrationPage />} />
    <Route path="prihlaseni" element={<LoginPage />} />
    <Route path="zapomenute-heslo" element={<ForgottenPasswordPage />} />
    <Route path="resetovat-heslo/:token" element={<ResetPasswordPage />} />
    <Route path="email-odeslan" element={<EmailSentPage />} />
    <Route path="pozvanka/:token" element={<ClaimInvitationPage />} />
    <Route index element={<Navigate to="prihlaseni" />} />
  </Route>
);

export default AuthRoutes;

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const propTypes = {
  fullPage: PropTypes.bool,
  small: PropTypes.bool,
  noMessage: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
};
const defaultProps = {
  fullPage: false,
  small: false,
  noMessage: false,
  color: 'primary',
};

const Loading = ({ fullPage, small, noMessage, color }) => {
  return (
    <Box
      sx={{
        height: fullPage && '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: small ? 6 : 12,
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        p: noMessage ? 0 : small ? 4 : 8,
      }}
    >
      <CircularProgress color={color} size={noMessage ? 30 : small ? 50 : 80} />
      {!noMessage && <Typography variant="h6">Načítají se data...</Typography>}
    </Box>
  );
};

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;

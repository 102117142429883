import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import createAuthState from './state';

export const context = createContext();

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

const AuthenticationProvider = ({ children }) => {
  const state = useMemo(() => createAuthState(), []);

  return <context.Provider value={state}>{children}</context.Provider>;
};

AuthenticationProvider.propTypes = propTypes;

export default AuthenticationProvider;

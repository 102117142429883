import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Loading from '../components/loading';

import AuthRoutes from './auth';
import AdminRoutes from './admin';
import UserRoutes from './user';
import ResellerRoutes from './reseller';

const UserAccountProvider = lazy(() => import('../modules/user/context'));
const OnlyNotAuthenticated = lazy(() => import('../modules/auth/components/onlyNotAuthenticated'));
const OnlyAuthenticated = lazy(() => import('../modules/auth/components/onlyAuthenticated'));
const OnlyAdmin = lazy(() => import('../modules/auth/components/onlyAdmin'));
const OnlyReseller = lazy(() => import('../modules/auth/components/onlyReseller'));
const OnlyUser = lazy(() => import('../modules/auth/components/onlyUser'));
const AuthenticationLayout = lazy(() => import('../modules/layouts/auth'));
const Error = lazy(() => import('../components/error'));

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading fullPage />}>
        <Routes>
          <Route layout element={<OnlyNotAuthenticated />}>
            {AuthRoutes}
          </Route>

          <Route layout element={<OnlyAuthenticated />}>
            <Route layout element={<OnlyAdmin />}>
              {AdminRoutes}
            </Route>

            <Route layout element={<UserAccountProvider />}>
              <Route layout element={<OnlyReseller />}>
                {ResellerRoutes}
              </Route>
              <Route layout element={<OnlyUser />}>
                {UserRoutes}
              </Route>
            </Route>
          </Route>

          <Route layout element={<AuthenticationLayout />}>
            <Route path="*" element={<Error message="Tato stránka neexistuje" />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;

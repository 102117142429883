import { firstValueFrom } from 'rxjs';

const createCustomFetch = (authState) => async (uri, opts) => {
  const authHeaders = await firstValueFrom(authState.getAuthHeaders$());

  return fetch(uri, { ...opts, headers: { ...opts.headers, ...authHeaders } }).then((response) => {
    if (response.status === 401) {
      window.localStorage.clear();
      window.location.reload();
    }

    return response;
  });
};

export default createCustomFetch;
